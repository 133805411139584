<template>
  <div class="wrap">
    <div class="title">{{ $t("inviteCode.title") }}</div>
    <el-form
        :model="inviteCodeForm"
        ref="inviteCodeForm"
        label-width="200px"
        class="demo-ruleForm"
        hide-required-asterisk=""
        style="width: 600px"
    >
      <el-form-item
          :label="$t('inviteCode.inviteCodeLabel')"

      >
        <el-input
            type="inviteCode"
            v-model.number="inviteCodeForm.inviteCode"
            autocomplete="off"
        ></el-input>
        <div class="tip" @click="alertTip">
          <i class="el-icon-question"></i>
        </div>
        <p class="text">*{{ $t("inviteCode.explain") }}</p>
      </el-form-item>
      <el-form-item></el-form-item>
      <el-form-item>
        <el-button
            type="primary"
            @click="submitForm('inviteCodeForm')"
            style="width: 100%"
        >{{ $t("inviteCode.over") }}
        </el-button
        >
      </el-form-item>


      <el-form-item>
        <router-link to>
          <el-button
              type="primary"
              plain
              style="width: 100%"
          >{{ $t("inviteCode.prev") }}
          </el-button
          >
        </router-link>
      </el-form-item>

    </el-form>
  </div>
</template>
<script>
import {register} from "@/api/User";
import {mapState, mapMutations} from "vuex";
import {getToken, setToken} from "@/utils/auth";

export default {
  data() {
    return {
      Authorization: getToken(),
      inviteCodeForm: {
        inviteCode: "",
      },
    };
  },
  methods: {
    submitForm(inviteCodeForm) {
      this.$refs[inviteCodeForm].validate((valid) => {
        if (valid) {
          this.toSubmint();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    toSubmint() {

      register(this.$route.params, this.inviteCodeForm.inviteCode).then(res => {

        // window.sessionStorage.setItem("Authorization",res);
        setToken(res)
        this.$message({
          message: "登录成功", type: "success",
          onClose: (res) => {
            this.$router.push("/");
          },
        });
      }).catch(err => {

      })
    },
    alertTip() {
      this.$alert(
          this.$t("inviteCode.mesAlertContent"),
          this.$t("inviteCode.mesAlertTitle"),
          {
            confirmButtonText: this.$t("inviteCode.mesAlertConfirm"),
            callback: (action) => {
            },
          }
      );
    },
  },
};
</script>

<style scoped>
.wrap {
  margin: 0 auto;
  margin-top: 80px;
  width: 800px;
}

.title {
  text-align: center;
  font-size: 24px;
  margin: 30px auto;
}

.tip {
  position: absolute;
  right: 15px;
  top: 0;
  color: #555;
  cursor: pointer;
  font-size: 20px;
}

.text {
  margin-top: 10px;
  color: #409eff;
  line-height: 24px;
}
</style>
